

































































































































import { Component, Mixins, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator'

import { FormErrorsMixin } from '../../../../support/mixins'
import { InputField } from '../../../shared/services/inputmask/config'
import { InputMaskMixin } from '../../../shared/mixins/inputmask.mixin'

import { AddressFormData, AddressFormFieldsetProps } from './AddressFormFieldset.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<AddressFormFieldset>({
  name: 'AddressFormFieldset',
  created () {
    this.model = this.formData
    this.model.country = 'PL'
  },
  mounted () {
    if (this.phoneRef?.$el) {
      this.maskInputField(
        'PL',
        InputField.Phone,
        this.phoneRef.$el?.querySelector('input')
      )
    }
  }
})
export class AddressFormFieldset extends Mixins(FormErrorsMixin, InputMaskMixin) {
  /**
   * List of all countries to deliver.
   */
  @Prop({ type: Array, required: true })
  public countries!: AddressFormFieldsetProps['countries']

  /**
   * @override
   */
  @Prop({ type: Object, required: false, default: null })
  public errors!: AddressFormFieldsetProps['_errors']

  /**
   * Wheteher billing address should be the same as shipping address.
   */
  @PropSync('sameBillingAddress', { type: Boolean, required: false, default: false })
  public isSameBillingAddress!: AddressFormFieldsetProps['sameBillingAddress']

  /**
   * Wheteher invoice form should be visible.
   */
  @PropSync('hasInvoice', { type: Boolean, required: false, default: false })
  public requestInvoice!: AddressFormFieldsetProps['hasInvoice']

  /**
   * Form's payload.
   */
  @Prop({ type: Object, required: true })
  protected readonly formData!: AddressFormData

  @Ref('phoneRef')
  public phoneRef!: Vue

  public model: AddressFormData = this.formData

  /**
   * Handles update of `addInvoice` model.
   */
  public onRequestInvoiceUpdate (value: boolean): void {
    if (!value) {
      const modelCopy = { ...this.model }
      delete modelCopy.invoice

      this.model = modelCopy
    } else {
      this.model = {
        ...this.model,
        invoice: {
          company: '',
          nip: ''
        }
      }
    }

    this.requestInvoice = value
    this.$emit('hasInvoice', value)
  }

  /**
   * Handles update of `model` in sameBilling Address checkbox.
   */
  public onSameBillingAddressUpdate (value: boolean): void {
    this.isSameBillingAddress = value
    this.$emit('sameBillingAddress', value)
  }

  @Watch('model', { deep: true })
  protected onModelChange (value: AddressFormData): void {
    this.$emit('update:formData', value)
    this.$emit('update:errors')
  }

  @Watch('formData')
  private updateFormData (): void {
    this.model = this.formData
  }
}

export default AddressFormFieldset
