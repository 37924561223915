
import { customerDetailsValidatorsMap } from './CustomerDetailsStep.helpers'
import { CustomerDetailsStepConfig } from './CustomerDetailsStep.contracts'

/**
 * @author Filip Rurak <fililp.rurak@movecloser.pl>
 */
export const CUSTOMER_DETAILS_STEP_CONFIG_MAP: CustomerDetailsStepConfig = {
  buttonGoNextTheme: 'secondary',
  setIsSignupRequestedInCart: false,
  shouldFormatPostalCode: false,
  sendCustomerDetailsEvent: false,
  validatorsMap: customerDetailsValidatorsMap,
  updateCartOnMount: false,
  excludedSocialsLogin: []
}

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const CUSTOMER_DETAILS_STEP_COMPONENT_KEY = 'CustomerDetailsStep'
