























import {
  AnyObject,
  Authentication,
  AuthServiceType,
  EventbusType,
  IEventbus
} from '@movecloser/front-core'
import { Component, Inject as VueInject, Mixins } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

import StructureConfigurableMixin from '../../../../support/mixins/StructureConfigurable.mixin.vue'
import { AuthControlServiceType, IAuthControl, SignupFormPayload } from '../../../auth/contracts'
import { CartAnalyticsMixin } from '../../../shared/mixins/cart-analytics.mixin'
import { CartBillingAddress, CartShippingAddress } from '../../../../contexts'
import { DictionaryServiceType, IDictionaryService } from '../../../shared/services/dictionary'
import { IProfileService, ProfileServiceType } from '../../../profile/contracts'
import { UserModel } from '../../../auth/shared'
import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY, logger } from '../../../../support'
import { validatePayload } from '../../../shared/support/validate-payload'

import {
  AddressFormData,
  AddressFormFieldset,
  AddressFormFieldsetProps
} from '../../molecules/AddressFormFieldset'
import { CART_ID_KEY, CartServiceType, ICartService } from '../../services/cart'
import {
  GuestDetailsFormData,
  GuestDetailsFormFieldset
} from '../../molecules/GuestDetailsFormFieldset'
import { NoteForm } from '../../molecules/NoteForm'
import { translateCheckoutPayloadToValidationObject } from '../../helpers/checkout.helpers'
import { CartMutationTypes } from '../../contracts'

import { AbstractStep } from '../AbstractStep'
import { customerDetailsValidatorsMap } from './CustomerDetailsStep.helpers'
import {
  CUSTOMER_DETAILS_STEP_COMPONENT_KEY,
  CUSTOMER_DETAILS_STEP_CONFIG_MAP
} from './CustomerDetailsStep.config'
import { ValidationRules } from '../../../shared/molecules/Form'
import { CustomerDetailsStepConfig } from './CustomerDetailsStep.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<CustomerDetailsStep>({
  name: 'CustomerDetailsStep',
  components: {
    AddressFormFieldset,
    GuestDetailsFormFieldset,
    NoteForm
  },
  async created (): Promise<void> {
    this.config = this.getComponentConfig(
      CUSTOMER_DETAILS_STEP_COMPONENT_KEY,
      { ...CUSTOMER_DETAILS_STEP_CONFIG_MAP })

    // todo: czy to nie jest duplikat funkcjonalnosci z shared/mixins/init-cart.mixin.ts:101 ?
    // await this.updateCartOnInit()
  },
  mounted (): void {
    if (this.cart) {
      this.eventBus.emit('app:checkout.begin', this.getBaseCheckoutPayload(this.cart))
    }
  }
})
export class CustomerDetailsStep extends Mixins(AbstractStep,
  CartAnalyticsMixin, StructureConfigurableMixin) {
  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(CartServiceType)
  public readonly cartService!: ICartService

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(DictionaryServiceType)
  protected readonly dictionaryService!: IDictionaryService

  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: defaultProvider(false) })
  public readonly isMobileProvider!: () => boolean

  public config!: CustomerDetailsStepConfig

  public errors: Record<string, string[]> | null = null

  public get hasErrors (): boolean {
    return !!this.errors && Object.keys(this.errors).length > 0
  }

  public get validatorsMap (): ValidationRules {
    return this.getConfigProperty('validatorsMap')
  }

  /**
   * Determines whether `isSignupRequested` property has to be taken from current `cart`
   */
  public get setIsSignupRequestedInCart (): boolean {
    return this.getConfigProperty('setIsSignupRequestedInCart')
  }

  public get addressFormData (): AddressFormData {
    return this.payload.address
  }

  public set addressFormData (data: AddressFormData) {
    this.onChange('address', data)
  }

  public get countries (): AddressFormFieldsetProps['countries'] {
    return this.dictionaryService.countries.map(country => ({
      label: country.fullNameLocale,
      value: country.id
    }))
  }

  public get isDisabled (): boolean {
    return false
  }

  public get note (): string {
    return this.payload.note
  }

  public set note (note: string) {
    this.onChange('note', note)
  }

  public get pages (): Record<string, string> {
    return this.siteService?.getActiveSiteUrls()
  }

  public get sendCustomerDetailsEvent (): boolean {
    return this.getConfigProperty('sendCustomerDetailsEvent')
  }

  public get updateCartOnMount (): boolean {
    return this.getConfigProperty('updateCartOnMount')
  }

  public get excludedSocialsLogin (): Array<string> {
    return this.getConfigProperty('excludedSocialsLogin') ?? []
  }

  public clearErrors (): void {
    this.errors = null
  }

  public async initCartOnRegister (guestCartId: string): Promise<string> {
    this.$store.commit(CartMutationTypes.SetRegisteringInCheckout, true)
    let cartId = guestCartId
    try {
      const customerCart = await this.cartService.loadCustomerCart()
      const combinedCart = await this.cartService.mergerCarts(guestCartId, customerCart.id)

      this.$store.commit(CartMutationTypes.SetCartId, combinedCart.id)
      localStorage.setItem(CART_ID_KEY, combinedCart.id)
      cartId = combinedCart.id
    } catch (e) {
      logger(e, 'warn')
      // this.showToast((e as Error).message, 'danger')
    } finally {
      this.$store.commit(CartMutationTypes.SetLoading, false)
      this.$store.commit(CartMutationTypes.SetRegisteringInCheckout, false)
    }

    return cartId
  }

  public static isValidStep (
    payload: AnyObject,
    translate: (key: string) => TranslateResult,
    locale: string,
    setErrors?: (errors: Record<string, string[]>) => void,
    validatorsMap?: ValidationRules
  ): boolean {
    const result = validatePayload(
      payload,
      validatorsMap ?? customerDetailsValidatorsMap,
      translate,
      locale
    )

    if (result.errors && setErrors) {
      setErrors(result.errors)
    }

    return !result.errors || Object.keys(result.errors).length === 0
  }

  public onSignUpRequest (request: boolean): void {
    this.onChange('isSignupRequested', request)
  }

  public setErrors (errors: Record<string, string[]>): void {
    this.errors = errors
  }

  public setHasInvoice (value: boolean): void {
    this.onChange('hasInvoice', value)
  }

  public setSameBillingAddress (value: boolean): void {
    this.onChange('sameBillingAddress', value)
  }

  public async updateCartOnInit (): Promise<boolean | undefined> {
    if (!this.cart || !this.updateCartOnMount) {
      return
    }

    this.$emit('saving', true)

    try {
      const freshCart = await this.cartService.loadCartByIdentifier(this.cart.id)
      this.$store.commit(CartMutationTypes.SetCart, freshCart)
    } catch (e) {
      logger((e as Error).message)
    } finally {
      this.$emit('saving', false)
    }
  }

  public async submit (): Promise<void> {
    if (!this.cart) {
      return
    }

    if (
      !CustomerDetailsStep.isValidStep(
        translateCheckoutPayloadToValidationObject({
          ...this.payload,
          isSignupRequested: !!this.userData && this.setIsSignupRequestedInCart ? false : this.payload.isSignupRequested
        }),
        this.$t.bind(this),
        this.$i18n.locale,
        this.setErrors,
        this.validatorsMap
      )
    ) {
      return
    }

    this.$emit('saving', true)
    try {
      if (!this.userData && this.payload.isSignupRequested) {
        const tokenModel = await this.authControl.signup({
          ...this.payload.user,
          acceptPrivacy: true,
          subscribeToNews: false
        } as SignupFormPayload)

        this.authService.setToken(tokenModel.toAuthToken())

        const cartId = await this.initCartOnRegister(this.cart.id)

        if (!this.authService.token) {
          return
        }

        this.$emit('saving', true)

        await this.profileService.storeDeliveryAddress({
          firstName: this.payload.user.firstName ?? '',
          lastName: this.payload.user.lastName ?? '',
          postalCode: this.payload.address.postalCode,
          vatId: this.payload.address.invoice?.nip,
          city: this.payload.address.city,
          company: this.payload.address.company,
          street: this.payload.address.address,
          defaultBilling: true,
          defaultShipping: false,
          countryCode: this.payload.address.country,
          phoneNumber: this.payload.address.phone
        })

        await this.profileService.getUserDetails(this.authService.token.accessToken).then((user) => {
          this.authService.setUser(user)
          this.$store.commit('profile/SET_USER', user)
        })

        this.handleCustomerDetailsEvent()
        this.$nextTick(this.continueSubmit.bind(this, cartId, this.payload.selectedAddressId))
      } else {
        if (!this.payload.isUserLoggedIn) {
          await this.checkoutService.setGuestEmail(this.cart.id, this.payload.user.email)
        }

        // this.handleCustomerDetailsEvent()
        await this.continueSubmit(this.cart.id, this.payload.selectedAddressId)
      }
    } catch (e) {
      this.$emit('saving', false)
      logger(e, 'warn')
      this.$emit('error', (e as Error).message)
    }
  }

  protected handleCustomerDetailsEvent (): void {
    if (!this.sendCustomerDetailsEvent) {
      return
    }

    this.eventBus.emit('app:customer_details', {
      firstname: this.payload.user.firstName ?? '',
      lastname: this.payload.user.lastName ?? '',
      company: this.payload.address.company ?? '',
      street: this.payload.address.address ?? '',
      postalCode: this.payload.address.postalCode ?? '',
      city: this.payload.address.city ?? '',
      countryCode: this.payload.address.country ?? '',
      phone: this.payload.address.phone ?? '',
      email: this.payload.user.email ?? '',
      vatId: this.payload.address.invoice?.nip ?? ''
    })
  }

  public updateGuestFormData (data: GuestDetailsFormData) {
    this.onChange('user', data)
  }

  protected async continueSubmit (cartId: string, addressId?: number | null): Promise<void> {
    await this.checkoutService.setShippingAddress(cartId, this.cartShippingAddress)

    let cart = await this.checkoutService.setBillingAddress(
      cartId,
      this.cartBillingAddress
    )

    if (addressId) {
      cart = await this.checkoutService.setSelectedAddressIdOnCart(cartId, addressId)
    }

    if (this.setIsSignupRequestedInCart && this.payload.isSignupRequested) {
      cart = await this.checkoutService.setIsSignupRequestedOnCart(cart.id, this.payload.isSignupRequested)
    }

    this.$store.commit(CartMutationTypes.SetCart, cart)

    // FIXME
    this.$emit('saving', false)
    this.nextStep()
  }

  private get cartBillingAddress (): CartBillingAddress {
    let address

    if (this.payload.sameBillingAddress) {
      address = {
        city: this.payload.address.city,
        postcode: this.payload.address.postalCode,
        street: [this.payload.address.address]
      }
    } else {
      address = {
        city: this.payload.address.billingAddress.city || this.payload.address.city,
        postcode: this.payload.address.billingAddress.postalCode || this.payload.address.postalCode,
        street: [this.payload.address.billingAddress.address || this.payload.address.address]
      }
    }

    return {
      ...address,
      company: this.payload.address.invoice?.company || this.payload.address.company,
      countryCode: this.payload.address.country,
      firstname: this.payload.user.firstName ?? '',
      lastname: this.payload.user.lastName ?? '',
      sameAsShipping: this.payload.hasInvoice ? false : this.payload.sameBillingAddress,
      telephone: this.payload.address.phone,
      vatId: this.payload.address.invoice?.nip
    }
  }

  private get cartShippingAddress (): CartShippingAddress {
    return {
      city: this.payload.address.city,
      company: this.payload.address.company,
      countryCode: this.payload.address.country,
      customerNotes: this.note,
      firstname: this.payload.user.firstName ?? '',
      lastname: this.payload.user.lastName ?? '',
      postcode: this.payload.address.postalCode,
      street: [this.payload.address.address],
      telephone: this.payload.address.phone,
      vatId: this.payload.address.invoice?.nip
    }
  }
}

export default CustomerDetailsStep
